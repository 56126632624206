<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 110px)">
        <div class="row mx-0 align-items-center">
            <div class="btn-back" @click="irDetalle">
                <i class="icon-left f-12" />
            </div>
            <div class="col-auto text-general f-18 px-2">
                Editar Promocion
            </div>
            <el-checkbox v-model="model.promocionActiva" :true-label="1" :false-label="0" class="check-green ml-auto" @change="editarPromocion($event,'estado')">
                Promoción activa
            </el-checkbox>
            <div class="btn-general mx-2 px-2" @click="irDetalle">
                ir al detalle
            </div>
        </div>
        <div class="row mx-0 mt-3 bg-white br-8 px-4 py-4">
            <p class="col-12 text-general f-18 mb-4">
                Datos generales
            </p>
            <div class="col-12">
                <div class="row mx-0">
                    <div class="col-4 pl-0">
                        <small class="text-general pl-3">
                            Nombre de la promoción
                        </small>
                        <el-input v-model="model.nombre" placeholder="Nombre" class="w-100 mb-4" size="small" @change="editarPromocion($event,'nombre')" />
                        <small class="text-general pl-3">
                            Condiciones
                        </small>
                        <el-input v-model="model.condiciones" type="textarea" :rows="4" size="small" placeholder="Condiciones" class="w-100" @change="editarPromocion($event,'condiciones')" />
                    </div>
                    <div class="col-4 pt-4">
                        <div style="height:170px;">
                            <img v-if="model.imagen != null" :src="model.imagen" width="100%" class="br-12 obj-cover cr-pointer" alt="" @click="modalCambiarImagen" />
                            <img v-else src="/img/no-imagen/mercado.svg" width="100%" height="170px" class="br-12 obj-cover cr-pointer" alt="" @click="modalCambiarImagen" />
                        </div>
                    </div>
                </div>
                <div class="row d-middle mt-3">
                    <div class="col-2 mx-0">
                        <p class="text-general f-12">
                            Hora inicio
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required" name="Hora inicio">
                            <el-time-select
                            v-model="model.hora_inicio"
                            class="w-100"
                            size="small"
                            placeholder="Hora inicio"
                            :picker-options="{
                                start: '00:00',
                                step: '00:30',
                                end: '23:30'
                            }"
                            @change="editarPromocion($event,'hora_inicio')"
                            />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-2 mx-0">
                        <p class="text-general f-12">
                            Hora fin
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required" name="Hora fin">
                            <el-time-select
                            v-model="model.hora_fin"
                            class="w-100"
                            size="small"
                            placeholder="Hora fin"
                            :picker-options="{
                                start: '00:00',
                                step: '00:30',
                                end: '23:30',
                                minTime: model.hora_inicio
                            }"
                            @change="editarPromocion($event,'hora_fin')"
                            />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 mt-3">
                    <el-checkbox v-model="model.acumulable" :true-label="1" :false-label="2" @change="editarPromocion($event,'acumulable')">
                        Acumulable con otras promociones.
                    </el-checkbox>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-3 mb-4">
            <div class="col-auto px-4" />
            <div class="col-4">
                <small class="pl-3 text-general">
                    Tipo de promoción
                </small>
                <el-select v-model="model.tipo" size="small" class="w-100" @change="editarPromocion($event,'tipo')">
                    <el-option
                    v-for="item in tiposPromociones"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
        </div>
        <div class="row mx-0 bg-white br-8 px-4 py-4">
            <p class="col-12 text-general f-16 mb-4">
                Puedes destacar el producto con una etiquete de texto.
            </p>
            <div class="col-12">
                <div class="row mx-0">
                    <div class="col">
                        <p class="text-general f-16 f-600 mb-3">
                            Aplicación a los productos
                        </p>
                        <div v-if="model.tipo == 3" class="row my-3">
                            <div class="col-auto pl-0 d-middle">
                                <input v-model="categoriaEspecifico" :value="1" type="radio" class="option-input green radio mx-1 ml-3" @change="editarPromocion(3,'tipo')" />
                                <span class="text-general f-16 mx-1">
                                    Productos especificos
                                </span>
                            </div>
                            <div class="col-auto d-middle">
                                <input v-model="categoriaEspecifico" :value="0" type="radio" class="option-input green radio mx-1 ml-3" @change="editarPromocion(6,'tipo')" />
                                <span class="text-general f-16 mx-1">
                                    Categoría completa
                                </span>
                            </div>
                        </div>
                        <div class="row mx-0 align-items-center">
                            <div class="col-5 px-0">
                                <small class="text-general pl-3">
                                    Seleccionar categoría
                                </small>
                                <el-select v-model="idCategoria" :disabled="model.categoriaTodas == 1" placeholder="Seleccionar" size="small" class="w-100" @change="guardarCategoria">
                                    <el-option
                                    v-for="item in categorias"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                            </div>
                            <el-checkbox v-model="model.categoriaTodas" class="check-green mt-4 ml-3" :true-label="1" :false-label="2" @change="editarPromocion($event,'categorias')">
                                Cualquier categoría
                            </el-checkbox>
                        </div>
                        <div v-if="model.categoriaTodas == 2" class="row mx-0 mt-3">
                            <div v-for="(c,k) in selectCategorias" :key="k" class="mn-pill bg-general3 text-white pl-2 m-1">
                                {{ c.nombre }} <i class="icon-cancel ml-2 f-15 cr-pointer" @click="eliminarCategoria(c.id)" />
                            </div>
                        </div>
                        <!-- Dias de aplicacion -->
                        <template v-if="categoriaEspecifico == 0">
                            <p class="f-16 text-general f-600 mt-4">
                                Días de aplicación
                            </p>
                            <div class="row mx-0 my-2">
                                <div class="col-auto pl-0 d-middle">
                                    <input v-model="diasAplicacion" :value="1" type="radio" class="option-input green radio mx-1 ml-3" @change="editarPromocion(0,'dias_semana')" />
                                    <span class="text-general f-16 mx-2">
                                        Días especificos
                                    </span>
                                </div>
                                <div class="col-auto d-middle">
                                    <input v-model="diasAplicacion" :value="0" type="radio" class="option-input green radio mx-1 ml-3" @change="editarPromocion('[0,0,0,0,0,0,0]','dias_semana')" />
                                    <span class="text-general f-16 mx-2">
                                        Días de la semana
                                    </span>
                                </div>
                            </div>
                            <div v-if="diasAplicacion == 0" class="row mx-0 my-4 px-3">
                                <div v-for="(dy, d) in dias" :key="d" :class="`container-dia${dy.activo ? '-activo' : ''} mr-2 mb-2 cr-pointer`" @click="diasSemana(dy)">
                                    {{ dy.dia }}
                                </div>
                            </div>
                            <div v-if="diasAplicacion == 1" class="row mx-0">
                                <div class="col-8 mb-3">
                                    <small class="text-general pl-3">
                                        Fecha
                                    </small>
                                    <el-date-picker
                                    v-model="fecha"
                                    type="date"
                                    size="small"
                                    class="w-100"
                                    placeholder="Seleccionar"
                                    start-placeholder="Inicio"
                                    :picker-options="pickerOptions"
                                    end-placeholder="Fin"
                                    value-format="yyyy-MM-dd"
                                    format="dd-MM-yyyy"
                                    @change="guardarFecha"
                                    />
                                </div>
                                <div v-for="(f,k) in fechas" :key="k" class="col-8 d-middle pl-0 mb-2">
                                    <div class="col text-general tres-puntos">
                                        {{ formatearFecha(f.fecha,'ddd DD MMM YYYY') }}
                                    </div>
                                    <i class="icon-delete-trash f-17 text-general cr-pointer" @click="eliminarFecha(f.id)" />
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="col">
                        <p class="text-general f-16 f-600 mb-3">
                            Personalización
                        </p>
                        <el-checkbox v-model="model.borde" class="check-green" :true-label="1" :false-label="0" @change="editarPromocion($event,'borde')">
                            Borde de color en el producto
                        </el-checkbox>
                        <div class="row mx-0 mt-3">
                            <div v-if="model.tipo == 3" class="col-4 px-0">
                                <small class="text-general pl-3">
                                    Descuento en %
                                </small>
                                <el-input v-model="model.descuento" placeholder="%" maxlength="3" size="small" class="w-100" @change="editarPromocion($event,'descuento')" />
                            </div>
                            <div class="col-4 px-0 mx-3">
                                <small class="text-general pl-3">
                                    Texto de la etiqueta
                                </small>
                                <el-input v-model="model.texto" placeholder="Etiqueta" maxlength="12" size="small" class="w-100" @change="editarPromocion($event,'texto')" />
                            </div>
                        </div>
                        <p class="text-general f-18 mt-4">
                            Vista previa del producto
                        </p>
                        <card-promocion :borde="model.borde" :texto="model.texto" />
                    </div>
                </div>
            </div>
        </div>
        <modal ref="modalImagen" titulo="Cambiar imagen promoción" no-aceptar adicional="Guardar" @adicional="cambiarImagen">
            <div class="row ">
                <div class="col-12 py-2">
                    <slim-cropper ref="imagenBanner" :options="slimOptions" class="border cr-pointer" style="background-color:#F5F5F5;border-radius:12px;" />
                    <p class="text-grey text-right f-12">
                        Relación Aspecto 6:2, Min: 600px Ancho x 200px Alto
                    </p>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import Promociones from "~/services/promociones/promociones_grupo";
import moment from "moment";
export default {
    components: {
        cardPromocion:  () => import('./components/cardPromocion')
    },
    data(){
        return {
            model: {
                id: null,
                nombre: '',
                condiciones: '',
                promocionActiva: 1,
                tipo: '',
                color:2,
                borde: 0,
                posicion: 2,
                texto: '',
                descuento: 0,
                categoriaTodas: 1,
                dias_semana: 1,
                imagen: null,
                acumulable: '',
                hora_inicio: '00:01',
                hora_fin: '23:59',
            },
            tiposPromociones: [{
                value: 2,
                label: 'Rebajado (Valor)'
            }, {
                value: 3,
                label: 'Descuento fijo (Porcentaje)'
            }, {
                value: 4,
                label: 'Descuento variable (Porcentaje)'
            }, {
                value: 5,
                label: 'Destacado'
            }],
            slimOptions: {
                ratio: '6:2',
                minSize:'600,200',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            idCategoria: '',
            diasAplicacion: 0,
            dias: [
                { id: 1, dia: 'Lun.', activo: false },
                { id: 2, dia: 'Mar.', activo: false },
                { id: 3, dia: 'Mie.', activo: false },
                { id: 4, dia: 'Jue.', activo: false },
                { id: 5, dia: 'Vie.', activo: false },
                { id: 6, dia: 'Sáb.', activo: false },
                { id: 7, dia: 'Dom.', activo: false }
            ],
            stringDias: '[0,0,0,0,0,0,0]',
            fecha: '',
            fechas: [],
            categorias: [],
            selectCategorias: [],
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < Date.now()
                }
            },
            categoriaEspecifico: 1,
        }
    },
    computed: {
        idGrupo(){
            return this.$route.params.idGrupo;
        },
        idPromocion(){
            return this.$route.params.idPromocion;
        },
    },
    watch:{
        diasAplicacion(val){
            if (val == 1){
                this.getFechas()
            }
        }
    },
    mounted(){
        this.getPromocion()
        this.getCategoria()
        this.promocionCategorias()
        if (this.diasAplicacion == 1){
            this.getFechas()
        }
    },
    methods: {
        async getPromocion(){
            try {
                const {data} = await Promociones.getPromocion({idPromocion:this.idPromocion})
                this.model.nombre = data.data.nombre
                this.model.promocionActiva = data.data.estado
                this.model.condiciones = data.data.condiciones
                this.model.imagen = data.data.imagen
                this.model.categoriaTodas = data.data.categorias
                this.model.borde = data.data.borde
                this.model.texto = data.data.texto
                this.model.descuento = data.data.descuento
                this.model.dias_semana = data.data.dias_semana
                this.model.acumulable = data.data.acumulable
                this.model.hora_inicio = data.data.hora_inicio
                this.model.hora_fin = data.data.hora_fin

                if (data.data.tipo == 6){
                    this.categoriaEspecifico = 0
                    this.model.tipo = 3
                }else {
                    this.model.tipo = data.data.tipo
                }

                if (data.data.dias_semana != 0){
                    this.diasAplicacion = 0
                    let dias = JSON.parse(data.data.dias_semana)

                    let that = this
                    dias.forEach(function(item,indice){
                        if (item == 1){
                            that.dias[indice].activo = true
                        }else {
                            that.dias[indice].activo = false
                        }
                    })
                }else if (data.data.dias_semana == 0){
                    this.diasAplicacion = 1
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        modalCambiarImagen(){
            if (this.model.imagen != null){
                this.$refs.imagenBanner.set_image(this.model.imagen)
            }
            this.$refs.modalImagen.toggle();
        },
        async cambiarImagen(){
            try {
                const payload = {
                    idPromocion : this.idPromocion,
                    campo: 'imagen',
                    data: this.$refs.imagenBanner.instanciaCrop.dataBase64.output.image
                }

                await Promociones.putPromocion(payload)
                this.$refs.modalImagen.toggle();
                this.getPromocion()
            } catch (e){
                this.error_catch(e)
            }
        },
        async editarPromocion(valor,columna){
            try {
                if (columna == 'descuento' && valor > 100 ){
                    await this.notificacion('No válido', 'El descuento no debe ser mayor a 100 %', 'warning')
                    return
                }

                const payload = {
                    idPromocion : this.idPromocion,
                    campo: columna,
                    data: valor
                }

                await Promociones.putPromocion(payload)

                this.notificacion('Mensaje', 'Guardado exitoso', 'success')

                if (columna == 'categorias' && this.model.categoriaTodas == 1){
                    this.selectCategorias = []
                    this.getCategoria()
                }
                if (columna == 'tipo' && (valor != 3 && valor != 6)){
                    this.diasAplicacion = 0
                    this.fechas = []
                    this.stringDias = '[0,0,0,0,0,0,0]'
                    this.categoriaEspecifico = 1
                }
                if (columna == 'dias_semana'){
                    this.fechas = []
                    this.fecha = ''
                    this.stringDias = '[0,0,0,0,0,0,0]'
                }
                this.getPromocion()
            } catch (e){
                this.error_catch(e)
            }
        },
        async getCategoria(){
            try {
                const {data} = await Promociones.SelectCategorias({idPromocion : this.idPromocion})
                this.categorias = data?.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async promocionCategorias(){
            try {
                const {data} = await Promociones.promocionCategorias({idPromocion : this.idPromocion})
                this.selectCategorias = data?.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async guardarCategoria(id){
            try {
                const payload = {
                    idPromocion : this.idPromocion,
                    idCategoria : id,
                }
                await Promociones.postCategoria(payload)
                this.notificacion('Mensaje', 'Guardado exitoso', 'success')

                const categoria = this.categorias.filter(c => c.id == id)
                this.selectCategorias.push(categoria[0])

                this.categorias = this.categorias.filter(e => e.id != id)
                this.idCategoria = ''
            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarCategoria(idCategoria){
            try {
                this.selectCategorias = this.selectCategorias.filter(e => e.id != idCategoria)

                await Promociones.deleteCategoria(idCategoria,{idPromocion: this.idPromocion})
                this.notificacion('Mensaje', 'eliminado exitoso', 'success')
                this.getCategoria()
            } catch (e){
                this.error_catch(e)
            }
        },
        diasSemana(value){
            value.activo = !value.activo

            let data = []
            this.dias.forEach(function(item){
                if (item.activo == false){
                    data.push(0)
                }else {
                    data.push(1)
                }
            })

            this.stringDias = `[${String(data)}]`
            this.editarPromocion(this.stringDias,'dias_semana')
        },
        async guardarFecha(date){
            if (date){
                let fecha = this.fechas.filter(f=>f.fecha === date)

                if(fecha.length == 0){
                    await Promociones.postFecha({idPromocion: this.idPromocion, fecha: date})
                    this.notificacion('Mensaje', 'Guardado exitoso', 'success')
                    this.getFechas()
                    this.fecha = ''
                }
            }
        },
        async getFechas(){
            try {
                const {data} = await Promociones.getFechas({idPromocion : this.idPromocion})
                this.fechas = data?.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarFecha(idFecha){
            try {
                await Promociones.deleteFecha(idFecha,{idPromocion: this.idPromocion})
                this.notificacion('Mensaje', 'eliminado exitoso', 'success')
                this.getFechas()
            } catch (e){
                this.error_catch(e)
            }
        },
        irDetalle(){
            this.$router.push({name: "admin.promociones.detalle", params:{idPromocion:this.idPromocion}})
        }
    }
}
</script>
<style lang="scss" scoped>
.container-dia{
    width: 50px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #DFE4E8;
    background-color: #F6F9FB;
    color: #637381;
    display: flex;
    align-items: center;
    justify-content: center;
    &-activo{
        @extend .container-dia;
        background-color: #000000 !important;
        color: #FFFFFF !important;
    }
}
</style>
