import axios from 'axios'

const API = 'promociones'

const PROMOCIONES = {
    guardarPromocion(payload){
        return axios.post(`${API}/crear`,payload)
    },
    getPromocion(params){
        return axios.get(`${API}/listar-detalle`,{params})
    },
    putPromocion(payload){
        return axios.put(`${API}/editar`,payload)
    },
    SelectCategorias(params){
        return axios.get(`${API}/select-categorias`,{params})
    },
    promocionCategorias(params){
        return axios.get(`${API}/categorias`,{params})
    },
    postCategoria(payload){
        return axios.post(`${API}/guardar/categoria`,payload)
    },
    deleteCategoria(idCategoria,params){
        return axios.delete(`${API}/categoria/${idCategoria}`,{params})
    },
    postFecha(payload){
        return axios.post(`${API}/guardar/fecha`,payload)
    },
    getFechas(params){
        return axios.get(`${API}/fechas`,{params})
    },
    deleteFecha(idFecha,params){
        return axios.delete(`${API}/fecha/${idFecha}`,{params})
    },

}

export default PROMOCIONES
